import { enums, formatters } from "@fraction/shared";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { ChecklistApp } from "src/api/fraction";
import { ApplicantOverview } from "src/apps/BrokerDashboard/components/ApplicantOverview";
import { ApplicationOverview } from "src/apps/PreFundedDashboard/components/ApplicationOverview";
import { AppTimeline } from "src/components/AppTimeline";
import { CancelOrReopenApp } from "src/components/CancelOrReopenApp";
import EmployeeCard from "src/components/EmployeeCard";
import { InnerFundingProgress } from "src/components/FundingProgress";
import HorizontallyCollapsibleDrawer from "src/components/HorizontallyCollapsibleDrawer";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { nestedNavLinkStyle } from "./ui/navigation-menu";

type Tab = "overview" | "doc-gen" | "doc-upload" | "comms";

const Header = ({ tabs }: { tabs?: Tab[] }) => {
  const { id } = useParams();
  if (!tabs?.length || tabs.length < 2) {
    return null;
  }

  return (
    <div className="flex items-center border-b-[1px] border-solid">
      <div className="pt-1 overflow-x-scroll gap-x-0.5 flex flex-row">
        {tabs.includes("overview") ? (
          <NavLink className={nestedNavLinkStyle} to={`/app/${id}/`}>
            Overview
          </NavLink>
        ) : null}
        {tabs.includes("doc-gen") ? (
          <NavLink className={nestedNavLinkStyle} to={`/app/${id}/doc-gen`}>
            Doc Creation
          </NavLink>
        ) : null}
        {tabs.includes("doc-upload") ? (
          <NavLink className={nestedNavLinkStyle} to={`/app/${id}/doc-upload`}>
            Doc Upload
          </NavLink>
        ) : null}
        {tabs.includes("comms") ? (
          <NavLink className={nestedNavLinkStyle} to={`/app/${id}/comms`}>
            Communications
          </NavLink>
        ) : null}
      </div>
    </div>
  );
};

export function AppOverviewShell({
  app,
  loading,
  tabs,
}: { app?: ChecklistApp; loading?: boolean; tabs?: Tab[] }) {
  const title = app?.id ? formatters.application.applicantNames(app) : "App details";
  useDocumentTitle(title);

  const bdm =
    app?.internalEmployees?.find((contact) => contact.type === enums.InternalEmployeeType.BDM) ||
    app?.internalEmployees?.find((contact) => contact.types?.includes(enums.InternalEmployeeType.BDM)) ||
    app?.internalEmployees?.find((contact) => contact.type === enums.InternalEmployeeType.INSIDE_SALES);

  const mortgageSpecialist =
    app?.internalEmployees?.find(
      (contact) => contact.type === enums.InternalEmployeeType.MORTGAGE_SPECIALIST
    ) ||
    app?.internalEmployees?.find((contact) =>
      contact.types?.includes(enums.InternalEmployeeType.MORTGAGE_SPECIALIST)
    );

  return (
    <div className="flex flex-col md:flex-row">
      <HorizontallyCollapsibleDrawer
        direction="left"
        expandedClassName="md:min-w-[300px] md:max-w-[400px] flex-1"
        collapsedClassName="flex-[0.05] max-w-4"
        className="p-4 flex flex-col gap-y-3 md:overflow-y-scroll md:h-[92svh]"
      >
        <div className="w-full">
          <div className="w-full flex flex-row justify-between">
            <p className="text-md font-medium">{title}</p>
            <CancelOrReopenApp appId={app?.id} loading={loading} status={app?.status} />
          </div>
          <InnerFundingProgress
            rejectReason={app?.declineReason || undefined}
            loading={loading}
            status={app?.status}
            className="mt-2 w-full"
            blockClassName="h-2 w-full"
          />
        </div>
        <ApplicationOverview
          showHeader={false}
          className="border-0 p-0 overflow-y-clip overflow-x-clip"
          loading={loading}
          app={app || undefined}
        />
        <ApplicantOverview
          detailsClassName="border-0 p-0 w-full"
          className="border-0 p-0 overflow-y-clip"
          app={app || undefined}
          loading={loading}
        />
      </HorizontallyCollapsibleDrawer>
      <div className="p-4 pt-2 flex flex-col gap-y-2 border-x-[1px] flex-[2] md:overflow-y-scroll md:h-[92svh]">
        <Header tabs={tabs} />
        <Outlet />
      </div>
      <HorizontallyCollapsibleDrawer
        direction="right"
        expandedClassName="md:min-w-[300px] md:max-w-[400px] flex-1"
        collapsedClassName="flex-[0.05] max-w-8"
        className="p-4 flex flex-col gap-y-1"
      >
        {mortgageSpecialist && bdm ? (
          <>
            <p className="text-lg font-bold">Fraction contacts</p>
            <EmployeeCard type="BDM" className="w-full border border-gray-200 p-4 rounded-md" contact={bdm} />
            <EmployeeCard
              type="Mortgage specialist"
              className="w-full border border-gray-200 p-4 rounded-md"
              contact={mortgageSpecialist}
            />
            <AppTimeline applicationId={app?.id} />
          </>
        ) : null}
      </HorizontallyCollapsibleDrawer>
    </div>
  );
}
