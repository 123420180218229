import { formatters, selectors } from "@fraction/shared";
import { FolderClosed, FolderOpenDot, Stamp } from "lucide-react";
import { Link, useParams } from "react-router-dom";
import Skeleton from "src/components/Skeleton";
import { BreadcrumbItem, BreadcrumbLink, BreadcrumbSeparator } from "src/components/ui/breadcrumb";
import { useApplicationQuery } from "src/hooks/useApplication";

const useActiveDealName = () => {
  const { id } = useParams();
  const { data: application, isLoading } = useApplicationQuery({ id });

  if (isLoading || !application) {
    return <Skeleton width={100} />;
  }

  return formatters.application.applicantNames(application);
};

const useActiveDealStatus = () => {
  const { id } = useParams();
  const { data: application, isLoading } = useApplicationQuery({ id });

  if (isLoading || !application) {
    return <Skeleton width={100} />;
  }

  const text = application
    ? selectors.application.isActiveDeal(application)
      ? "Active"
      : "Closed"
    : undefined;

  return text;
};

const AppIdBreadcrumb = () => {
  const { id } = useParams();
  const dealName = useActiveDealName();
  const status = useActiveDealStatus();

  return (
    <>
      <BreadcrumbItem>
        <BreadcrumbLink asChild>
          <Link to={status === "Active" ? "/" : "/closed"}>{status} deals</Link>
        </BreadcrumbLink>
      </BreadcrumbItem>
      <BreadcrumbSeparator />
      <BreadcrumbItem>
        <BreadcrumbLink asChild>
          <Link to={`/app/${id}`}>{dealName}</Link>
        </BreadcrumbLink>
      </BreadcrumbItem>
    </>
  );
};

const dashboardConfig = {
  sidebarLinks: [
    {
      icon: FolderOpenDot,
      text: "Active deals",
      to: "/",
      nested: [{ to: "/app/:id", breadcrumbs: AppIdBreadcrumb }],
    },
    {
      icon: FolderClosed,
      text: "Closed deals",
      to: "/closed",
      nested: [{ to: "/app/:id", breadcrumbs: AppIdBreadcrumb }],
    },
    {
      icon: Stamp,
      text: "Marketing materials",
      to: "/marketing/",
    },
  ],
};

export default dashboardConfig;
