import { SerializedStyles } from "@emotion/react";
import React, { ReactNode } from "react";
import { createStyles } from "src/styles";

// the maximum width content on the page can stretch to
export const PAGE_WIDTH = 1120;

const styles = createStyles(({ spacing }) => ({
  outerContainer: {
    display: "flex",
    justifyContent: "flex-start",
    padding: ["40px 24px", "40px 60px"],
    height: "100%",
  },
  innerContainer: {
    maxWidth: PAGE_WIDTH,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  heading: {
    paddingBottom: spacing[1],
  },
  subheader: {
    marginBottom: spacing[2],
  },
  spacer: {
    width: "100%",
    padding: spacing[1],
  },
}));

/**
 * A wrapper component that adds max width and padding to the page. Optionally adds a heading and subheading. Applicable to most dashboard pages.
 */
const DashboardPage = ({
  children,
  heading,
  subHeading,
  maxWidth,
  outerContainerStyle,
  className,
}: {
  children: ReactNode;
  heading?: ReactNode;
  subHeading?: ReactNode;
  maxWidth?: number | string;
  outerContainerStyle?: SerializedStyles;
  className?: string;
}) => {
  return (
    <div css={[styles.outerContainer, outerContainerStyle]}>
      <div className={className} css={[styles.innerContainer, { maxWidth: maxWidth || PAGE_WIDTH }]}>
        <div>
          {heading ? <h1 className="font-serif-deck text-4xl">{heading}</h1> : null}
          {subHeading ? <h2 className="text-base font-light mt-1 max-w-2xl">{subHeading}</h2> : null}
        </div>
        {children}
        <div css={styles.spacer} />
      </div>
    </div>
  );
};

export default React.memo(DashboardPage);
