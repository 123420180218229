import { ReactNode } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Header from "src/apps/PostFundedDashboard/components/Header";
import NavDrawer from "src/apps/PostFundedDashboard/components/NavDrawer";
import { NavItems } from "src/apps/PreFundedDashboard/components/NavItems";
import { Documents } from "src/apps/PreFundedDashboard/screens/documents";
import { PrefundedDashboardOverview } from "src/apps/PreFundedDashboard/screens/overview";
import { DashboardShell, FooterDisclosure } from "src/components";
import MissingRouteRedirect from "src/components/root/routeHelpers/MissingRouteRedirect";
// import PreFundedSidebar from "./components/Sidebar";

import IncomeVerify from "./screens/income-verify";

const PreFundedDashboardLayout = ({ footer }: { footer?: ReactNode }) => (
  <>
    <DashboardShell
      footer={footer}
      header={<Header />}
      // sidebar={<PreFundedSidebar />}
    >
      <Outlet />
    </DashboardShell>
    <NavDrawer NavItems={NavItems} />
  </>
);

const Footer = () => (
  <div className="pb-4 px-8 w-[95%] mt-auto translate-y-[100px]">
    <FooterDisclosure />
  </div>
);

export const PreFundedDashboard = () => {
  return (
    <Routes>
      <Route element={<PreFundedDashboardLayout footer={<Footer />} />}>
        <Route index element={<PrefundedDashboardOverview />} />
        <Route path="income-verify" element={<IncomeVerify />} />
        <Route path="documents" element={<Documents />} />
        <Route path="*" element={<MissingRouteRedirect />} />;
      </Route>
    </Routes>
  );
};
