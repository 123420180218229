import { ReactNode } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { NavItems } from "src/apps/ConveyancerDashboard/components/NavItems";
import NavDrawer from "src/apps/PostFundedDashboard/components/NavDrawer";
import { HeaderBreadcrumb } from "src/apps/PreFundedDashboard/components/HeaderBreadcrumb";
import { DashboardShell } from "src/components";
import ConveyancerHeader from "./components/Header";
import dashboardConfig from "./config";
import { AppDetailsRoutes } from "./pages/AppDetails";
import { ConveyancerDashboardOverview } from "./pages/Overview";

const ConveyancerDashboardHeader = () => {
  return <ConveyancerHeader Breadcrumbs={<HeaderBreadcrumb config={dashboardConfig} />} className="pl-6" />;
};

const ConveyancerDashboardLayout = ({ footer }: { footer?: ReactNode }) => {
  return (
    <>
      <DashboardShell footer={footer} header={<ConveyancerDashboardHeader />}>
        <Outlet />
      </DashboardShell>
      <NavDrawer NavItems={NavItems} />
    </>
  );
};

export function ConveyancerDashboardRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ConveyancerDashboardLayout />}>
        <Route index path="/" element={<ConveyancerDashboardOverview status="active" />} />
        <Route path="/app/:id/*" element={<AppDetailsRoutes />} />
      </Route>
    </Routes>
  );
}
