import { Link, matchPath, useLocation } from "react-router-dom";
import dashboardConfig from "src/apps/BrokerDashboard/config";
import { BreadcrumbItem, BreadcrumbLink } from "src/components/ui/breadcrumb";

export const HeaderBreadcrumb = ({ config }: { config: typeof dashboardConfig }) => {
  const location = useLocation();
  const matchedNestedPath = config.sidebarLinks
    ?.find((link) => link?.nested?.find((nested) => matchPath({ path: nested.to }, location.pathname)))
    ?.nested?.find((nested) => matchPath({ path: nested.to }, location.pathname));

  if (matchedNestedPath?.breadcrumbs) {
    return <matchedNestedPath.breadcrumbs />;
  }

  const matchedTopPath = config.sidebarLinks.find((link) => matchPath({ path: link.to }, location.pathname));
  if (!matchedTopPath) {
    return null;
  }

  return (
    <BreadcrumbItem>
      <BreadcrumbLink asChild>
        <Link to={matchedTopPath.to}>{matchedTopPath.text}</Link>
      </BreadcrumbLink>
    </BreadcrumbItem>
  );
};
