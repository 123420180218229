import { ReactNode } from "react";
import { FractionAppQrCode } from "src/apps/BrokerDashboard/components/FractionAppQrCode";

export const TitleWithQr = ({ title, description }: { title: string; description: string | ReactNode }) => (
  <div className="flex flex-row justify-between flex-wrap items-center">
    <div className="flex flex-col gap-2">
      <h1 className="text-3xl font-serif-deck">{title}</h1>
      <p className="text-base font-normal mb-2 max-w-xl">{description}</p>
    </div>
    <FractionAppQrCode />
  </div>
);
