import { ReactNode } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Header from "src/apps/PostFundedDashboard/components/Header";
import NavDrawer from "src/apps/PostFundedDashboard/components/NavDrawer";
import { NavItems } from "src/apps/PostFundedDashboard/components/NavItems";
import PostFundedSidebar from "src/apps/PostFundedDashboard/components/Sidebar";
import Documents from "src/apps/PostFundedDashboard/pages/Documents";
import Marketplace from "src/apps/PostFundedDashboard/pages/Marketplace";
import MonthlyStatements from "src/apps/PostFundedDashboard/pages/MonthlyStatements";
import PaymentsAndDraws from "src/apps/PostFundedDashboard/pages/PaymentsAndDraws";
import { DashboardShell, FooterDisclosure } from "src/components";
import MissingRouteRedirect from "src/components/root/routeHelpers/MissingRouteRedirect";

const PostFundedDashboardLayout = ({ footer }: { footer?: ReactNode }) => (
  <>
    <DashboardShell footer={footer} header={<Header />} sidebar={<PostFundedSidebar />}>
      <Outlet />
    </DashboardShell>
    <NavDrawer NavItems={NavItems} />
  </>
);

const Footer = () => (
  <div className="pb-4 px-8 w-[95%] mt-auto translate-y-[100px]">
    <FooterDisclosure />
  </div>
);

export const PostFundedDashboard = () => {
  return (
    <Routes>
      <Route element={<PostFundedDashboardLayout footer={<Footer />} />}>
        <Route index element={<PaymentsAndDraws />} />
        <Route path="monthly-statements" element={<MonthlyStatements />} />
        <Route path="documents" element={<Documents />} />
        <Route path="marketplace" element={<Marketplace />} />
        <Route path="*" element={<MissingRouteRedirect />} />;
      </Route>
    </Routes>
  );
};
