import { FolderOpenDot } from "lucide-react";
import { RouterNavLink } from "src/components";

export const NavItems = ({ onClick }: { onClick?: () => any }) => {
  return (
    <>
      <RouterNavLink onClick={onClick} icon={FolderOpenDot} to="/">
        Active deals
      </RouterNavLink>
    </>
  );
};
