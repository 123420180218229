import { HelpCircleIcon } from "lucide-react";
import NanoClamp from "nanoclamp";
import { ReactNode } from "react";
import Skeleton from "src/components/Skeleton";
import Tooltip from "src/components/Tooltip";
import { useClipboard } from "src/hooks/useClipboard";
import { cn } from "src/utilities/shadcnUtils";

export const KeyValue = ({
  label,
  value,
  loading,
  className,
  clipboard = true,
  tooltip,
}: {
  label: string;
  value?: string | ReactNode;
  loading?: boolean;
  className?: string;
  clipboard?: boolean;
  tooltip?: ReactNode;
}) => {
  const onClick = useClipboard();
  return (
    <div className={cn("flex flex-row items-center justify-between p-3 rounded", className)}>
      <div className="flex flex-row">
        <p className="text-sm font-bold">{label}:</p>
        {tooltip ? (
          <Tooltip white text={tooltip}>
            <HelpCircleIcon className="ml-1" size={12} />
          </Tooltip>
        ) : null}
      </div>
      {loading ? (
        <Skeleton width={160} />
      ) : typeof value === "string" ? (
        <NanoClamp
          text={value || "N/A"}
          onClick={onClick}
          lines={2}
          className={cn("text-sm text-right", clipboard && "cursor-pointer hover:font-semibold")}
        />
      ) : (
        value
      )}
    </div>
  );
};
