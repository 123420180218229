import { SyntheticApplicationStatus, entities, enums } from "@fraction/shared";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import fraction from "src/api/fraction";
import { useCachedQueryClient } from "src/hooks/useCache";
import { addFile, setDeletedFile, updateFile } from "src/hooks/useChecklist";
import { useDebouncedCallback, useThrottledCallback } from "use-debounce";
import useModal from "./useModal";

export function useInsurancePolicy({
  dealId,
  existingInsurancePolicy,
  dealStatus,
  firstFileId,
}: {
  dealId: string;
  existingInsurancePolicy: entities.InsurancePolicy | undefined;
  dealStatus: SyntheticApplicationStatus | enums.LoanStatus;
  // the firstFileId is sort of a hack to create a new insurance policy if we have a file but no existing policy
  firstFileId?: string;
}) {
  const queryClient = useCachedQueryClient();

  const { showModal } = useModal();

  const insurancePolicyQ = useQuery<entities.InsurancePolicy | null>({
    queryKey: ["insurancePolicy", dealId, existingInsurancePolicy?.id],
    enabled: !!dealId && !!existingInsurancePolicy?.id,
    queryFn: async () => {
      if (!existingInsurancePolicy?.id) {
        return null;
      }
      return fraction.getInsurancePolicy(dealId, existingInsurancePolicy?.id);
    },
    placeholderData: existingInsurancePolicy,
  });

  const handleUpdatePolicy = useMutation({
    mutationFn: async ({
      policy,
      uploadedFileId,
    }: { policy: Partial<entities.InsurancePolicy>; uploadedFileId?: string }) => {
      if (existingInsurancePolicy?.id) {
        await fraction.updateInsurancePolicy(dealId, existingInsurancePolicy?.id, uploadedFileId, policy);
      } else {
        const fileId = uploadedFileId || firstFileId;
        if (!fileId) {
          throw new Error("No uploaded file id");
        }
        await fraction.createInsurancePolicy(dealId, fileId, policy);
      }
    },
  });

  const addNewFileToPolicy = useMutation({
    mutationFn: async (newFile: entities.UploadedFile) => {
      if (!newFile.id) {
        throw new Error("No uploaded file id");
      }
      await handleUpdatePolicy.mutateAsync({
        policy: { id: existingInsurancePolicy?.id },
        uploadedFileId: newFile.id,
      });
      addFile(queryClient, dealId, {
        ...newFile,
        insurancePolicy: existingInsurancePolicy,
        insurancePolicyId: existingInsurancePolicy?.id,
      });
    },
  });

  const [isDeleting, setIsDeleting] = useState<string | null | undefined>(null);
  const handleDeletePolicy = useMutation({
    mutationFn: async (file: entities.UploadedFile) => {
      return new Promise<void>((resolve) =>
        showModal({
          header: "Are you sure you want to delete this policy?",
          message: "This cannot be undone! If you are sure, press 'Delete'.",
          actions: [
            {
              type: "inverse",
              text: "Never mind",
              action: () => resolve(),
            },
            {
              type: "urgent",
              text: "Delete",
              action: async () => {
                setIsDeleting(file?.id);
                try {
                  if (!file?.id) {
                    throw new Error("No uploaded file id");
                  }
                  await fraction.deleteInsurancePolicyFile(dealId, file?.id);
                  queryClient.setQueryData(["insurancePolicy", dealId, existingInsurancePolicy?.id], null);
                  setDeletedFile(
                    queryClient,
                    dealId as string,
                    dealStatus as enums.ApplicationStatus,
                    file?.id
                  );
                } finally {
                  setIsDeleting(null);
                  resolve();
                }
              },
            },
          ],
        })
      );
    },
  });

  const throttledUpdate = useThrottledCallback(handleUpdatePolicy.mutateAsync, 1);
  const debouncedUpdate = useDebouncedCallback(throttledUpdate, 300);

  const localUpdatePolicy = useCallback(
    (policy: Partial<entities.InsurancePolicy>, uploadedFileId?: string) => {
      queryClient.setQueryData(
        ["insurancePolicy", dealId, policy.id || existingInsurancePolicy?.id],
        (prev: entities.InsurancePolicy | null | undefined) => ({ ...(prev || {}), ...policy })
      );
      if (uploadedFileId) {
        updateFile(queryClient, dealId, uploadedFileId, (file) => ({
          ...file,
          insurancePolicy: { ...(file?.insurancePolicy || {}), ...policy },
        }));
      }
    },
    [dealId, existingInsurancePolicy?.id]
  );

  const debouncedUpdatePolicy = useCallback(
    (policy: Partial<entities.InsurancePolicy>, uploadedFileId?: string) => {
      localUpdatePolicy(policy, uploadedFileId);

      return debouncedUpdate({ policy, uploadedFileId });
    },
    [dealId, debouncedUpdate, localUpdatePolicy]
  );

  const handleChangeExpiryDate = useCallback(
    (date?: Date) => {
      if (date) {
        return debouncedUpdatePolicy({ expiryDate: date });
      }
    },
    [debouncedUpdatePolicy]
  );

  const handleChangeStartDate = useCallback(
    (date?: Date) => {
      if (date) {
        return debouncedUpdatePolicy({ startDate: date });
      }
    },
    [debouncedUpdatePolicy]
  );

  const handleChangePolicyNumber = useCallback(
    (policyNumber: string) => {
      return debouncedUpdatePolicy({ policyNumber });
    },
    [debouncedUpdatePolicy]
  );

  const handleChangeInsuranceProvider = useCallback(
    (insuranceProvider: string) => {
      return debouncedUpdatePolicy({ insuranceProvider });
    },
    [debouncedUpdatePolicy, dealId]
  );

  handleDeletePolicy.isPending = !!isDeleting;

  return {
    insurancePolicy: insurancePolicyQ?.data,
    ...insurancePolicyQ,
    handleUpdatePolicy,
    handleChangeExpiryDate,
    handleChangeStartDate,
    handleChangePolicyNumber,
    handleChangeInsuranceProvider,
    handleDeletePolicy,
    localUpdatePolicy,
    addNewFileToPolicy,
    isPending: handleUpdatePolicy.isPending,
    isDeletingFile: isDeleting,
  };
}
