import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "src/utilities/shadcnUtils";
import Skeleton from "../Skeleton";
import ThreeDotsWave from "../ThreeDotsWave";

const buttonVariants = cva(
  "cursor-pointer flex flex-col inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground shadow hover:bg-primary/90",
        destructive: "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline: "border border-input bg-transparent shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        "ghost:green": "hover:bg-green-100 hover:text-green/90 text-green",
        "ghost:red": "hover:bg-red-100 hover:text-red/70 text-red",
        link: "text-primary underline-offset-4 hover:underline",
        green: "bg-green text-white shadow hover:bg-green/90",
        red: "bg-red text-white shadow hover:bg-red/90",
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const dotsVariants = cva("", {
  variants: {
    variant: {
      default: "bg-primary-foreground",
      destructive: "bg-destructive-foreground",
      outline: "bg-primary",
      secondary: "bg-secondary-foreground",
      ghost: "bg-primary",
      "ghost:green": "bg-green",
      "ghost:red": "bg-red",
      link: "bg-primary",
      green: "bg-green",
      red: "bg-red",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  pending?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, loading, pending, variant, size, asChild = false, children, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
        {pending ? (
          <div className="min-h-[24px] flex items-center justify-center">
            <ThreeDotsWave className={cn(dotsVariants({ variant }))} />
          </div>
        ) : loading ? (
          <Skeleton className="h-4 w-20" />
        ) : null}
        {/* Keep the content there but hidden so that the width does not change on loading (note this needs the container to have a flex-col) */}
        <div
          className={
            loading || pending
              ? "overflow-hidden h-0 flex flex-row justify-between items-center"
              : "cursor-pointer flex flex-row justify-between items-center"
          }
        >
          {children}
        </div>
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
