import { enums } from "@fraction/shared";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "src/auth";
import { Skeleton } from "src/components";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "src/components/ui/navigation-menu";
import { cn } from "src/utilities/shadcnUtils";

export function ServicingNavMenu() {
  const { user, isLoadingUser } = useAuth();

  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavLink to="/portfolio">
            <p className={navigationMenuTriggerStyle()}>Map</p>
          </NavLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavLink to="/portfolio/analytics">
            <p className={navigationMenuTriggerStyle()}>Analytics</p>
          </NavLink>
        </NavigationMenuItem>
        {isLoadingUser ? (
          <Skeleton className="w-20 h-5" />
        ) : user?.permissions?.includes(enums.Permission.SERVICING_DETAILED_DATA) ? (
          <NavigationMenuItem>
            <NavLink to="/portfolio/loan-tape">
              <p className={navigationMenuTriggerStyle()}>Loan Tape</p>
            </NavLink>
          </NavigationMenuItem>
        ) : null}
        {isLoadingUser ? (
          <Skeleton className="w-20 h-5" />
        ) : user?.permissions?.includes(enums.Permission.SERVICING_DETAILED_DATA) ? (
          <NavigationMenuItem>
            <NavLink to="/portfolio/data">
              <p className={navigationMenuTriggerStyle()}>Data</p>
            </NavLink>
          </NavigationMenuItem>
        ) : null}
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = React.forwardRef<React.ElementRef<"a">, React.ComponentPropsWithoutRef<typeof NavLink>>(
  ({ className, title, children, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <NavLink
            ref={ref}
            className={cn(
              "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className
            )}
            {...props}
          >
            <div className="text-sm font-medium leading-none">{title}</div>
            {/* @ts-ignore */}
            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">{children}</p>
          </NavLink>
        </NavigationMenuLink>
      </li>
    );
  }
);
ListItem.displayName = "ListItem";
