import { Global, css } from "@emotion/react";
import { SquareArrowLeft, SquareArrowRight } from "lucide-react";
import { Children, ReactNode, cloneElement, isValidElement, useCallback } from "react";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

import { useCachedState } from "src/hooks/useCache";
import Popup, { PopupProps } from "../Popup";
import XButton from "../XButton";

export interface ModalBoxProps extends Omit<PopupProps, "children"> {
  width?: number | string;
  children: Array<ReactNode | boolean> | ReactNode | boolean;
  closeButton?: boolean | ReactNode;
  closeOnClickBackdrop?: boolean;
  closeOnEscape?: boolean;
  className?: string;
  shovable?: boolean;
}

const styles = createStyles({
  closeButton: {
    position: "absolute",
    top: 18,
    right: 18,
    backgroundColor: "transparent",
    zIndex: 10,
  },
});

const GLOBAL_STYLES = css`
  .popup-content {
    border-radius: 8px;
    max-width: 90%;
    width: 500px;
    min-height: 100px;
    padding: 0;
  }

  .popup-overlay {
    overflow-y: auto;
  }
`;

const ModalBox = ({
  children,
  width,
  closeButton = true,
  closeOnClickBackdrop = !!closeButton,
  closeOnEscape = !!closeButton,
  position,
  shovable,
  ...props
}: ModalBoxProps) => {
  const [shoved, setShoved] = useCachedState(false, "modal-shoved");

  const handleShove = useCallback(() => {
    setShoved((prev) => !prev);
  }, []);
  return (
    <>
      <Global styles={GLOBAL_STYLES} />
      <Popup
        nested
        overlayStyle={{ left: shoved ? "auto" : 0 }}
        contentStyle={{ width }}
        {...props}
        closeOnDocumentClick={closeOnClickBackdrop}
        closeOnEscape={closeOnEscape}
      >
        {/*// @ts-ignore */}
        {(close: () => void) => (
          <>
            {Children.map(
              children,
              (child) => isValidElement(child) && cloneElement(child, { onClose: close } as any)
            )}
            {closeButton === true ? (
              <XButton onClick={close} color={colors.palette.GREY_400} style={styles.closeButton} />
            ) : closeButton === false ? null : (
              closeButton
            )}
            {shovable ? (
              shoved ? (
                <SquareArrowLeft
                  onClick={handleShove}
                  className="absolute left-2 top-1/2 text-green cursor-pointer fill-green-200 z-10"
                />
              ) : (
                <SquareArrowRight
                  onClick={handleShove}
                  className="absolute right-2 top-1/2 text-green cursor-pointer fill-green-200 z-10"
                />
              )
            ) : null}
          </>
        )}
      </Popup>
    </>
  );
};

export default ModalBox;
