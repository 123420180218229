import { MouseEvent, useCallback } from "react";
import { toast } from "react-toastify";

const TOAST_OPTS = {
  autoClose: 1000,
  hideProgressBar: true,
  style: {
    backgroundColor: "rgba(212, 212, 216, 1)",
    borderRadius: "9999px",
    width: "fit-content",
    maxWidth: "fit-content",
  },
  bodyStyle: {
    color: "rgba(63, 63, 70, 1)",
    fontSize: 12,
    textAlign: "center" as const,
    fontWeight: 500,
    textWrap: "nowrap",
  },
  closeButton: false,
};

export function useClipboard(value?: string) {
  return useCallback(
    (evt: MouseEvent) => {
      // @ts-ignore
      const copyValue = value || evt.target?.innerText;
      evt.stopPropagation();
      if (!copyValue) {
        return;
      }
      navigator.clipboard.writeText(copyValue);
      toast(
        `Copied "${copyValue.slice(0, 40)}${copyValue.length > 40 ? "..." : ""}" to clipboard`,
        TOAST_OPTS
      );
    },
    [value]
  );
}
