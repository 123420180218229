import { entities, enums, plainToInstance, selectors, types } from "@fraction/shared";
import { UndefinedInitialDataInfiniteOptions, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { differenceInSeconds } from "date-fns";
import _ from "lodash";
import { useEffect, useMemo, useRef } from "react";
import fraction, { ChecklistApp } from "src/api/fraction";
import { useAuth } from "src/auth";
import { useCachedQuery, useCachedQueryClient } from "src/hooks/useCache";
import { UseQueryOptions, useMutation, useQuery } from "src/lib";
import { selectChecklistApplication } from "src/selectors";

export const APPLICATION_KEY = ["application"];

/**
 * When we just want to use the cache, we don't want to actually refetch the data ever here.
 */
export const useApplicationLocal = ({ id }: { id?: string }) => {
  const queryClient = useQueryClient();

  return useQuery<ChecklistApp | null>({
    enabled: !!id,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchIntervalInBackground: false,
    queryKey: [...APPLICATION_KEY, id],
    queryFn: async () => {
      return queryClient.getQueryData([...APPLICATION_KEY, id]) || null;
    },
  });
};

export const useApplicationAuthed = ({
  id,
  enabled = true,
  cached = true,
  refetch,
  initialRefetch,
}: {
  id?: string;
  enabled?: boolean;
  cached?: boolean;
  refetch?: boolean;
  initialRefetch?: boolean;
}) => {
  const { token } = useAuth();
  return useApplicationQuery({
    id,
    enabled: !!token && enabled,
    cached,
    refetch,
    initialRefetch,
  });
};

export const useApplication = ({
  id,
  enabled = true,
  fetchEnabled = true,
  cached,
  initialRefetch,
}: {
  id?: string;
  enabled?: boolean;
  fetchEnabled?: boolean;
  cached?: boolean;
  initialRefetch?: boolean;
}) => {
  const queryClient = useCachedQueryClient();

  const q = useApplicationQuery({ id, enabled: enabled && fetchEnabled, cached, initialRefetch });

  const updateApplication = useMutation({
    mutationFn: async (changes: Parameters<typeof fraction.updateApplication>[1]) => {
      if (!enabled || !id) {
        throw new Error("Not ready");
      }
      queryClient.setQueryData(["application", id], (prev: entities.ApplicationT) =>
        plainToInstance(entities.Application, { ...(prev || {}), ...changes, updatedAt: new Date() })
      );
      return fraction.updateApplication({ id: id }, changes);
    },
    onSuccess: (deal) => {
      queryClient.setQueryData(["application", id], (prev: entities.ApplicationT) => ({
        ...prev,
        ...deal,
        updatedAt: new Date(),
      }));
    },
  });

  const updateApplicationProperty = useMutation({
    mutationFn: async (changes: Parameters<typeof fraction.updateProperty>[1]) => {
      if (!enabled || !q?.data?.property?.id) {
        throw new Error("Not ready");
      }
      queryClient.setQueryData(["application", id], (prev: entities.ApplicationT) =>
        plainToInstance(entities.Application, {
          ...(prev || {}),
          updatedAt: new Date(),
          property: {
            ...(prev?.property || {}),
            ...changes,
          },
        })
      );
      return fraction.updateProperty({ id: q?.data?.property?.id }, changes);
    },
    onSuccess: (property) => {
      queryClient.setQueryData(["application", id], (prev: entities.ApplicationT) => ({
        ...(prev || {}),
        updatedAt: new Date(),
        property: {
          ...(prev?.property || {}),
          ...property,
        },
      }));
    },
  });

  const rescindDeal = useMutation({
    mutationFn: async ({ withdrawnReason }: { withdrawnReason: string }) => {
      if (!id) {
        throw new Error("No id");
      }
      queryClient.setQueryData(["application", id], (prev: entities.ApplicationT) => ({
        ...(prev || {}),
        status: enums.ApplicationStatus.HOMEOWNER_RESCINDED,
      }));

      return fraction.rescindDeal(id, withdrawnReason);
    },
  });

  const reopenDeal = useMutation({
    mutationFn: async (e: any) => {
      if (!id || !q?.data) {
        throw new Error("No id or app");
      }

      const lastOpenStatus = selectors.application.selectMostRecentOpenStatus(q?.data);
      queryClient.setQueryData(["application", id], (prev: entities.ApplicationT) => ({
        ...(prev || {}),
        status: lastOpenStatus?.status,
      }));

      return fraction.reopenDeal(id);
    },
    onSuccess: (deal) => {
      queryClient.setQueryData(["deal", id], (prev: any) => ({ ...(prev || {}), ...deal }));
    },
  });

  return {
    ...q,
    updateApplication: updateApplication.mutateAsync,
    updateApplicationProperty: updateApplicationProperty.mutateAsync,
    isPending: updateApplication.isPending || updateApplicationProperty.isPending,
    rescindDeal,
    reopenDeal,
  };
};

export const useApplicationQuery = ({
  id,
  enabled = true,
  refetch = false,
  cached = true,
  initialData,
  placeholderData,
  initialRefetch,
  accountType,
}: {
  id?: string;
  enabled?: boolean;
  refetch?: boolean;
  cached?: boolean;
  initialData?: ChecklistApp;
  placeholderData?: ChecklistApp;
  initialRefetch?: boolean;
  accountType?: "employee" | "broker" | "applicant" | "conveyancer";
}) => {
  return (cached ? useCachedQuery : useQuery)({
    initialRefetch,
    initialData,
    placeholderData,
    deserialize: selectChecklistApplication,
    refetchOnMount: refetch,
    refetchOnWindowFocus: refetch,
    refetchOnReconnect: refetch,
    refetchInterval: refetch === false ? false : undefined,
    enabled: enabled && !!id,
    queryKey: [...APPLICATION_KEY, id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      return fraction.getApplication(id, { userType: accountType });
    },
  });
};

const LIMIT = 150;
const limits = splitNumberEqually(LIMIT, 5);
const limitsAndOffsets = limits.map((limit, index) => {
  return {
    limit,
    offset: limits.slice(0, index).reduce((acc, curr) => acc + curr, 0),
  };
});

const usePaginatedApplicationQuery = (
  {
    status = "active",
    accountType,
    enabled = true,
    queryKey,
    offset,
    limit,
  }: {
    accountType?: "employee" | "broker" | "applicant" | "conveyancer";
    status?: "active" | "all" | "closed" | "funded" | "realization";
    enabled?: boolean;
    queryKey: (string | number | undefined)[];
    offset: number;
    limit: number;
  },
  options?: Partial<UseQueryOptions<any>>
) => {
  const cachedQueryClient = useCachedQueryClient();
  const queryClient = useQueryClient();

  return useCachedQuery<types.ArrayResponse<ChecklistApp>>({
    initialRefetch: true,
    deserialize: (data) => ({
      data: data?.data?.map(selectChecklistApplication),
      count: data?.count,
    }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    ...options,
    enabled,
    queryKey,
    queryFn: async () => {
      return await fraction.getApplications({
        status,
        accountType,
        offset,
        limit,
      });
    },
    onSuccess: async (apps, fromCache) => {
      const client = fromCache ? queryClient : cachedQueryClient;
      await Promise.all(
        apps?.data?.map(async (app) => {
          await Promise.all([
            client.setQueryData([...APPLICATION_KEY, app.id], (prev: ChecklistApp) =>
              prev ? Object.assign(prev, app) : app
            ),
          ]);
        }) || []
      );
    },
  });
};

export const useInfiniteApplicationsQuery = (
  {
    status = "active",
    accountType,
    enabled = true,
  }: {
    accountType?: "employee" | "broker" | "applicant" | "conveyancer";
    status?: "active" | "all" | "closed" | "funded" | "realization";
    enabled?: boolean;
  } = {},
  options?: Partial<UseQueryOptions<any>>
) => {
  // debugger;
  const { token } = useAuth();
  const cachedQueryClient = useCachedQueryClient();

  const queryKey = [...APPLICATION_KEY, "infinite", status || "all", accountType];

  const q0 = usePaginatedApplicationQuery({
    status,
    accountType,
    enabled: enabled && !!token,
    queryKey: [...queryKey, limitsAndOffsets[0].offset],
    offset: limitsAndOffsets[0].offset,
    limit: limitsAndOffsets[0].limit,
  });

  const q1 = usePaginatedApplicationQuery({
    status,
    accountType,
    enabled: enabled && !!token,
    queryKey: [...queryKey, limitsAndOffsets[1].offset],
    offset: limitsAndOffsets[1].offset,
    limit: limitsAndOffsets[1].limit,
  });

  const q2 = usePaginatedApplicationQuery({
    status,
    accountType,
    enabled: enabled && !!token,
    queryKey: [...queryKey, limitsAndOffsets[2].offset],
    offset: limitsAndOffsets[2].offset,
    limit: limitsAndOffsets[2].limit,
  });

  const q3 = usePaginatedApplicationQuery({
    status,
    accountType,
    enabled: enabled && !!token,
    queryKey: [...queryKey, limitsAndOffsets[3].offset],
    offset: limitsAndOffsets[3].offset,
    limit: limitsAndOffsets[3].limit,
  });

  const q4 = usePaginatedApplicationQuery({
    status,
    accountType,
    enabled: enabled && !!token,
    queryKey: [...queryKey, limitsAndOffsets[4].offset],
    offset: limitsAndOffsets[4].offset,
    limit: limitsAndOffsets[4].limit,
  });

  const q = {
    ...q0,
    isLoading: q0.isLoading || q1.isLoading || q2.isLoading || q3.isLoading || q4.isLoading,
    isFetching: q0.isFetching || q1.isFetching || q2.isFetching || q3.isFetching || q4.isFetching,
    isCacheLoading:
      q0.isCacheLoading || q1.isCacheLoading || q2.isCacheLoading || q3.isCacheLoading || q4.isCacheLoading,
    data: [
      ...(q0.data?.data || []),
      ...(q1.data?.data || []),
      ...(q2.data?.data || []),
      ...(q3.data?.data || []),
      ...(q4.data?.data || []),
    ],
    count: q0.data?.count,
  };

  const pq = useQuery<types.ArrayResponse<ChecklistApp>, any, types.ArrayResponse<ChecklistApp>>({
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: 60000,
    ...options,
    enabled: enabled && !q.isCacheLoading && !!token,
    queryKey: [...APPLICATION_KEY, status || "all", accountType, "poller"],
    queryFn: async () => {
      const apps = await fraction.getApplications({
        status,
        accountType,
        since: q.dataUpdatedAt ? Math.abs(differenceInSeconds(q.dataUpdatedAt, new Date()) * 2) : 60,
      });
      return apps;
    },
    onSuccess: (apps) => {
      for (const app of apps?.data || []) {
        cachedQueryClient.setQueryData([...APPLICATION_KEY, app.id], app);
        // for (const [status, checklist] of Object.entries(app?.checklists || {})) {
        //   cachedQueryClient.setQueryData(["checklist", app.id, status], checklist);
        // }
      }
    },
  });

  return {
    ...q,
    data: _.uniqBy([...(q.data || {}), ...(pq.data?.data || [])], "id"),
    count: pq.data?.count ?? q.count,
    refetch: pq.refetch,
    isFetching: q.isFetching || pq.isFetching,
  };
};

export const useApplicationsQuery = (
  {
    status = "active",
    accountType,
    enabled = true,
    refetch = true,
  }: {
    accountType?: "employee" | "broker" | "applicant" | "conveyancer";
    status?: "active" | "all" | "closed";
    enabled?: boolean;
    refetch?: boolean;
  } = {},
  options?: Partial<UseQueryOptions<any>>
) => {
  const { isAuthenticated, isLoadingUser } = useAuth();
  const queryClient = useCachedQueryClient();

  const q = useCachedQuery<
    types.ArrayResponse<ChecklistApp>,
    types.ArrayResponse<ChecklistApp>,
    ChecklistApp[]
  >({
    initialRefetch: refetch,
    deserialize: (data) =>
      data ? { ...data, data: data?.data?.map(selectChecklistApplication) } : undefined,
    // serialize: (data) => ({ data }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    ...options,
    enabled: enabled && isAuthenticated,
    queryKey: [...APPLICATION_KEY, status || "all", accountType],
    select: (data) => data?.data,
    queryFn: async () => {
      const apps = await fraction.getApplications({
        status,
        accountType,
      });

      for (const app of apps?.data || []) {
        await queryClient.setQueryData([...APPLICATION_KEY, app.id], (prev: ChecklistApp) => {
          return prev ? Object.assign(prev, app) : app;
        });
      }

      return apps;
    },
  });

  return {
    ...q,
    isLoading: q.isLoading || q.isCacheLoading || isLoadingUser,
  };
};

function splitNumberEqually(number: number, parts: number): number[] {
  const quotient = Math.floor(number / parts);
  const remainder = number % parts;

  const result = new Array(parts).fill(quotient);

  for (let i = 0; i < remainder; i++) {
    result[i]++;
  }

  return result;
}

/**
 * this will be useful once we have a ton of apps. for now, we just assume we have 150. This useInfiniteQuery stuff is hard to use that's why
 * i decided to not use it for now
 */
export const useOldInfiniteApplicationsQuery = (
  {
    status = "active",
    accountType,
    enabled,
  }: {
    accountType?: "employee" | "broker" | "applicant" | "conveyancer";
    status?: "active" | "all" | "closed";
    enabled?: boolean;
  } = {},
  options?: Partial<UndefinedInitialDataInfiniteOptions<any>>
) => {
  const queryClient = useQueryClient();
  const LIMIT = 100;
  const completedInitialFetch = useRef(false);

  const q = useInfiniteQuery<types.ArrayResponse<ChecklistApp>>({
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 10000,
    ...options,
    enabled,
    getNextPageParam: (lastPage, pages, lastPageParam) => {
      if (lastPage.data?.length === LIMIT) {
        return ((lastPageParam as number) || 0) + LIMIT;
      }
      return undefined;
    },
    initialPageParam: 0,
    queryKey: [...APPLICATION_KEY, "infinite", status || "all", accountType],
    queryFn: async ({ pageParam }) => {
      const apps = await (async () => {
        if (completedInitialFetch.current) {
          return fraction.getApplications({
            status,
            accountType,
            // if we haven't fetched yet, get all the new apps from the last hour
            since: completedInitialFetch.current ? 600 : undefined,
          });
        } else {
          const limits = splitNumberEqually(LIMIT, 5);
          const limitsAndOffsets = limits.map((limit, index) => {
            return {
              limit,
              offset: (pageParam as number) + limits.slice(0, index).reduce((acc, curr) => acc + curr, 0),
            };
          });

          const allApps = await Promise.all(
            limitsAndOffsets.map(({ limit, offset }) =>
              fraction.getApplications({
                status,
                accountType,
                offset,
                limit,
              })
            )
          );

          completedInitialFetch.current = true;

          return {
            ...allApps[allApps.length - 1],
            data: allApps.map((app) => app.data).flat(),
          };
        }
      })();

      if (status === "all" && accountType !== "employee") {
        queryClient.setQueryData([...APPLICATION_KEY, "active", accountType], (prev: any) => ({
          ...(prev || {}),
          data: apps.data?.filter((app) => selectors.application.isActiveDeal(app)),
        }));
        queryClient.setQueryData([...APPLICATION_KEY, "closed", accountType], (prev: any) => ({
          ...(prev || {}),
          pageParams: [0],
          data: apps.data?.filter((app) => !selectors.application.isActiveDeal(app)),
        }));
      }
      for (const app of apps?.data || []) {
        queryClient.setQueryData([...APPLICATION_KEY, app.id], app);

        for (const app of apps?.data || []) {
          queryClient.setQueryData([...APPLICATION_KEY, app.id], app);
          for (const [status, checklist] of Object.entries(app?.checklists || {})) {
            queryClient.setQueryData(["application", "checklist", app.id, status], checklist);
          }
        }
      }

      return apps;
    },
  });

  useEffect(() => {
    if (q.hasNextPage && !q.isFetchingNextPage) {
      q.fetchNextPage();
    }
  }, [q.hasNextPage, q.isFetchingNextPage]);

  useEffect(() => {
    return () => {
      completedInitialFetch.current = false;
    };
  }, []);

  const flattened = useMemo(
    () => _.sortBy(q.data?.pages?.map((d) => d.data).flat(), "updatedAt").reverse(),
    [q.data?.pages?.length]
  );

  return {
    ...q,
    data: flattened,
    count: q.data?.pages?.[0]?.count,
  };
};
