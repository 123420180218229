import { Bullet } from "src/components/Bullet";
import { Button } from "src/components/ui/button";
import { cn } from "src/utilities/shadcnUtils";
import Skeleton from "./Skeleton";

export const SectionHeader = ({
  onClick,
  loading,
  buttonText,
  Icon,
  header,
  className,
}: {
  onClick?: () => void;
  loading?: boolean;
  buttonText?: string;
  Icon?: any;
  header?: string;
  className?: string;
}) => {
  return (
    <div className={cn("flex flex-row items-center mb-1 h-8", className)}>
      {loading ? (
        <Skeleton className="h-[24px] w-[24px] mr-2 ml-[-2rem]" />
      ) : (
        <Icon
          height={24}
          width={24}
          className="text-gray bg-gray-200 rounded p-1 h-[24px] w-[24px] mr-2 ml-[-2rem]"
        />
      )}
      {loading ? (
        <Skeleton className="w-24 h-4" />
      ) : (
        <h4 className="text-xs font-semibold text-gray">{header}</h4>
      )}
      {onClick && buttonText ? <Bullet className="h-1 w-1 ml-3 mr-1" /> : null}
      {onClick && buttonText ? (
        <Button loading={loading} onClick={onClick} size="sm" variant="ghost:green">
          {buttonText}
        </Button>
      ) : null}
    </div>
  );
};
