import { ResponsiveBar } from "@nivo/bar";
import { useMemo } from "react";
import { useProjectedMaturities } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { Skeleton } from "src/components";
import { useToggler } from "src/hooks";

import { formatters } from "@fraction/shared";

import { format } from "date-fns";
import { ChartCard } from "../ChartCard";

const MARGIN = { top: 20, right: 0, bottom: 40, left: 80 };
const AXIS_BOTTOM = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 40,
  legendPosition: "middle",
  legendOffset: 32,
  format: (value: string) => format(new Date(value), "MMM yyyy"),
} as const;

const AXIS_BOTTOM_YEAR = {
  ...AXIS_BOTTOM,
  format: (value: string) => format(new Date(value), "yyyy"),
} as const;

const Tooltip = (props: any) => {
  return (
    <div className="shadow p-2 bg-white rounded">
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Volume: </b>
        {formatters.number.getCurrencyFromNumber(props.data?.volume)}
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Count: </b>
        {props.data?.count}
      </p>
    </div>
  );
};

export function ProjectedMaturitiesChart() {
  const { data, isLoading } = useProjectedMaturities();
  const { Toggle, on: showCount } = useToggler({
    id: "portfolioSizeToggle",
    label: "Show count",
    defaultValue: false,
  });
  const { Toggle: YearToggle, on: showYear } = useToggler({
    id: "portfolioMaturitiesYearToggle",
    label: "By year",
    defaultValue: false,
  });

  const aggregatedData = useMemo(() => {
    if (showYear) {
      const yearData = data?.reduce((acc: any, curr: any) => {
        const year = new Date(curr.month).getFullYear();
        if (!acc[year]) {
          acc[year] = {
            month: `${year}`,
            volume: 0,
            count: 0,
          };
        }
        acc[year].volume += formatters.number.getNumberFromString(curr.volume);
        acc[year].count += curr.count;
        return acc;
      }, {});
      return Object.values(yearData) as typeof data;
    }
    return data;
  }, [data, showYear]);

  if (isLoading) {
    return (
      <div className="col-span-2">
        <Skeleton width="100%" height={200} />
      </div>
    );
  }

  if (!aggregatedData) {
    return null;
  }

  return (
    <ChartCard
      isEmpty={aggregatedData.length === 0}
      cardClassName="col-span-2"
      className="h-[200px]"
      header="Projected maturities"
      headerComponents={
        <div className="flex row gap-2">
          <Toggle />
          <YearToggle className="ml-2" />
        </div>
      }
    >
      <ResponsiveBar
        data={aggregatedData}
        keys={showCount ? ["count"] : ["volume"]}
        indexBy={"month"}
        margin={MARGIN}
        padding={0.3}
        valueScale={{ type: "linear" }}
        tooltip={Tooltip}
        indexScale={{ type: "band", round: true }}
        groupMode="grouped"
        axisBottom={showYear ? AXIS_BOTTOM_YEAR : AXIS_BOTTOM}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          format: showCount ? undefined : formatters.number.getCurrencyWords,
        }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="#ffffff"
      />
    </ChartCard>
  );
}
