import { type VariantProps, cva } from "class-variance-authority";
import { Loader } from "lucide-react";
import randomcolor from "randomcolor";
import * as React from "react";

import { cn } from "src/utilities/shadcnUtils";
import Skeleton from "../Skeleton";

const defaultClassName =
  "px-0 hover:brightness-110 flex flex-col inline-flex items-center justify-center rounded-md border text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2";
const badgeVariants = cva(defaultClassName, {
  variants: {
    variant: {
      gray: "border-transparent bg-gray-300 text-gray-800 shadow hover:brightness-105",
      default: "border-transparent bg-primary text-primary-foreground shadow",
      blue: "border-transparent bg-blue text-white shadow",
      lightgreen: "border-transparent bg-green-400 text-green-foreground shadow",
      secondary: "border-transparent bg-yellow text-black",
      success: "border-transparent bg-green text-secondary-foreground",
      destructive: "border-transparent bg-destructive text-destructive-foreground shadow",
      outline: "text-foreground",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {
  loading?: boolean;
  pending?: boolean;
  childContainerClassName?: string;
}

function Badge({
  className,
  childContainerClassName,
  variant,
  loading,
  children,
  pending,
  ...props
}: BadgeProps) {
  const backgroundColor =
    !variant && typeof children === "string"
      ? randomcolor({
          seed: children,
          luminosity: "dark",
        })
      : undefined;

  return (
    <div
      style={{ backgroundColor }}
      className={cn(badgeVariants({ variant }), className, loading && "bg-gray-200 hover:bg-gray-200")}
      {...props}
    >
      {loading && (
        <Skeleton
          className={cn(defaultClassName, "w-full px-2.5 py-0.5 rounded-full border-none h-full")}
          containerClassName={cn("w-full h-full rounded-full border-none", childContainerClassName)}
        />
      )}
      <div
        className={cn(
          loading
            ? "overflow-hidden h-[0px] px-2.5 flex flex-row truncate items-center"
            : "px-2.5 py-0.5 flex flex-row items-center h-5 truncate",
          childContainerClassName
        )}
      >
        {pending ? <Loader height={13} className="animate-spin" /> : children}
      </div>
    </div>
  );
}

export { Badge, badgeVariants };
