import { constants } from "@fraction/shared";
import { FractionAppQR } from "src/images";

export const FractionAppQrCode = () => (
  <a
    className="p-4 bg-green-100 rounded flex flex-row items-center max-w-[340px] gap-2"
    target="_blank"
    href={constants.PROD_INVITE_LINK}
  >
    <div className="max-w-[200px]">
      <h2 className="font-serif-deck text-md text-wrap">Download the Fraction broker iPhone app</h2>
      <p className="text-sm font-light">
        Get notifications, make changes, upload docs, earn points, and more.
      </p>
    </div>

    <FractionAppQR className="min-w-[100px]" width={100} height={100} />
  </a>
);
