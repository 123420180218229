import { parsers } from "@fraction/shared";
import _ from "lodash";
import { Download, Loader } from "lucide-react";
import fraction from "src/api/fraction";
import { TitleWithQr } from "src/apps/BrokerDashboard/components/TitleWithQr";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";

import { useMutation } from "src/lib";
import { downloadFile } from "src/utilities/file/download";

const FileRow = ({ type, description }: { type: parsers.docGen.DocGenDocumentType; description: string }) => {
  return (
    <BasicFileRow
      href={fraction.previewDocumentDownloadUrl(type)}
      filename={_.startCase(type)}
      description={description}
    />
  );
};

const BasicFileRow = ({
  href,
  filename,
  description,
}: { href: string; filename: string; description: string }) => {
  const handleDownloadMutation = useMutation({
    mutationFn: (event: any) => {
      return downloadFile(href, `${filename}.pdf`);
    },
  });

  return (
    <button
      onClick={handleDownloadMutation.mutateAsync}
      className="max-w-[28rem] w-full flex flex-row items-center hover:bg-gray-100 justify-between border-gray-200 border-2 p-4 rounded"
    >
      <div className="text-left mr-6">
        <p className="text-lg font-semibold">{filename}</p>
        <p className="text-gray text-sm">{description}</p>
      </div>
      <div>
        {handleDownloadMutation.isPending ? (
          <Loader height={20} className="text-gray-600 animate-spin" />
        ) : (
          <Download width={20} />
        )}
      </div>
    </button>
  );
};

export const BrokerDocsAndResourcesPage = () => {
  useDocumentTitle("Docs & Resources");

  // 2xl:flex-nowrap
  return (
    <div className="p-6 flex flex-col gap-2">
      <TitleWithQr
        title="Sample documents"
        description="Looking to show your borrowers what loan agreements are coming their way? Download these sample documents to walk them through the files."
      />
      <div className="flex flex-row flex-wrap gap-2">
        <FileRow
          type={parsers.docGen.DocGenDocumentType.COMMITMENT_LETTER}
          description="The commitment letter that outlines the broad terms of the agreement between the borrower and Fraction."
        />
        <FileRow
          type={parsers.docGen.DocGenDocumentType.BORROWER_DISCLOSURE}
          description="A document that explains more details about the costs of the Fraction loan."
        />
        <FileRow
          type={parsers.docGen.DocGenDocumentType.LOAN_AGREEMENT}
          description="The final loan documents that the borrower will sign with their lawyer at the end of the process."
        />
      </div>
      <h1 className="text-3xl font-serif-deck mt-6">Other resources</h1>
      <p className="text-base font-normal mb-2 max-w-xl">
        Want to learn more about Fraction and our products? Learn more about our rates, terms, and more in the
        documents below.
      </p>
      <div className="flex flex-row flex-wrap gap-2">
        <BasicFileRow
          filename="Brokerage Onboarding package"
          description="Want to submit deals to Fraction? Send this form over to your brokerage manager or owner to fill out and have them send it back to us."
          href="https://cdn.prod.website-files.com/625e8796d2a09d3151afa845/66a191fd470adcf4c90300f4_Broker%20Onboarding%20Package%20(1).pdf"
        />
        <BasicFileRow
          filename="How Fraction looks at income"
          description="An overview of how Fraction looks at income and what we require clients to provide to prove their income."
          href="https://cdn.prod.website-files.com/625e8796d2a09d3151afa845/66197d84ea337d904d36f240_How%20Fraction%20looks%20at%20income.pdf"
        />
        <BasicFileRow
          filename="Independent Legal Representation (ILR) lawyer list"
          description="If your client is looking for a lawyer, here is a list of third-party lawyers who have provided ILR to Fraction clients in the past."
          href="https://cdn.prod.website-files.com/625e8796d2a09d3151afa845/65f21f911004373c0c19ff2a_List%20of%20ILRs.pdf"
        />
        <BasicFileRow
          filename="Working with Fraction: Fraction's process"
          description="Designed for mortgage professionals, learn about the steps involved in going from application to funding with Fraction."
          href="https://cdn.prod.website-files.com/625e8796d2a09d3151afa845/65f32d9817c192300572feb2_Working%20with%20Fraction%E2%80%94Fraction%27s%20process.pdf"
        />
        <BasicFileRow
          filename="Product details and funding timelines"
          description="Designed for mortgage professionals, this outlines Fraction's product and gives an overview of Fraction's funding timelines."
          href="https://cdn.prod.website-files.com/625e8796d2a09d3151afa845/65f21d31d4016adc638662d1_pro-brochure-1.pdf"
        />
        <BasicFileRow
          filename="Product overview for homeowners and family members"
          description="A one-pager overview of Fraction's product offering for homeowners and their family members."
          href="https://cdn.prod.website-files.com/625e8796d2a09d3151afa845/65e11e24f918db33ac753b5b_family-brochure.pdf"
        />
        <BasicFileRow
          filename="Fraction vs. a reverse mortgage"
          description="Fraction requires appraisals for all properties and requires inspections for loan amounts above $1,000,000."
          href="https://cdn.prod.website-files.com/625e8796d2a09d3151afa845/64ee6a396014d1b9b1fee1c9_Fraction_AppraisalInspection_Process.pdf"
        />
        <BasicFileRow
          filename="Appraisal and inspection guidelines"
          description="A one-pager overview of Fraction's product offering for homeowners and their family members."
          href="https://cdn.prod.website-files.com/625e8796d2a09d3151afa845/65e11e24f918db33ac753b5b_family-brochure.pdf"
        />
        <BasicFileRow
          filename="Simplified funding process"
          description="Designed for borrowers, check out this easy step-by-step guide to learn the ins-and-outs of the Fraction funding process."
          href="https://cdn.prod.website-files.com/625e8796d2a09d3151afa845/64ee60fc8275d03e32566836_Fraction_Process_CAN_1.pdf"
        />
        <BasicFileRow
          filename="Underwriting guidelines"
          description="Is Fraction right for your client? Download this guide to learn the specifics about Fraction's underwriting criteria."
          href="https://cdn.prod.website-files.com/625e8796d2a09d3151afa845/6675c53435b334ca0e014f61_Customer%20profile%2C%20underwriting%20policies%20and%20use%20of%20funds-June2024.pdf"
        />
        <BasicFileRow
          filename="Customer brochure"
          description="A detailed customer-facing brochure to help you explain the Fraction Mortgage to your client."
          href="https://cdn.prod.website-files.com/625e8796d2a09d3151afa845/6675c6587e49a2d4ee31c50a_Homeowner-Booklet-CAN-June2024.pdf"
        />
        <BasicFileRow
          filename="How to submit to Fraction"
          description={`How to find Fraction on Filogix Expert, Newton Velocity, and Finmo. A hint: it's under the "private lender" category.`}
          href="https://cdn.prod.website-files.com/625e8796d2a09d3151afa845/64ee60bf1b07b9e11da709f7_How%20Submit%20to%20Fraction.pdf"
        />
      </div>
    </div>
  );
};
