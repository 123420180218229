import { S3Bucket, entities } from "@fraction/shared";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import fraction from "src/api/fraction";
import { useAuth } from "src/auth";
import { FileWithEnumType } from "src/components/Dropzone";
import { addFile } from "src/hooks/useChecklist";
import { useCachedQueryClient } from "./useCache";

export function useUploadDocuments({
  onUploadSuccess,
}: { onUploadSuccess?: (file: entities.UploadedFile) => void }) {
  const { token } = useAuth();

  const queryClient = useCachedQueryClient();

  const uploadDocuments = useMutation({
    mutationFn: async ({
      assets,
      applicationId,
      documentTypeName,
      applicantId,
      debtId,
      assetId,
      incomeId,
    }: {
      assets: FileWithEnumType[];
      applicationId: string;
      documentTypeName: string;
      applicantId?: string;
      debtId?: string;
      assetId?: string;
      incomeId?: string;
    }) => {
      if (!token) {
        throw new Error("No token found");
      }

      const presignedUrls = await fraction.getPresignedFileUploadUrls({
        numberOfFiles: assets.length,
        applicationId,
      });

      await Promise.all(
        assets.map(async (asset, index) => {
          const presignedUrl = presignedUrls[index];
          await fraction.uploadFile(asset, presignedUrl.fileURL, asset.type);
        })
      );

      const newFile = await fraction.registerUploadedFilesToApplication({
        applicationId,
        applicantId,
        debtId,
        assetId,
        incomeId,
        documentTypeName: documentTypeName,
        uploadedFiles: presignedUrls.map((fileUrl) => ({
          s3Key: fileUrl.key,
          s3Bucket: fileUrl.bucket as S3Bucket,
        })),
      });

      addFile(queryClient, applicationId, newFile);

      return newFile;
    },
    onSuccess: (file: entities.UploadedFile) => {
      toast.success("Documents uploaded successfully");
      onUploadSuccess?.(file);
    },
    onError: (err) => {
      toast.error("Error uploading documents");
    },
  });

  return uploadDocuments;
}
