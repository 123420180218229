import { SerializedStyles } from "@emotion/react";
import { ReactNode, memo } from "react";
import { Badge, DashboardPage, Heading, Paragraph, PrimaryButton } from "src/components";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { useWindowSize } from "src/hooks";
import { Tag } from "src/icons";
import { CoveredLogo } from "src/images";
import { Style, createStyles } from "src/styles";

const styles = createStyles(({ spacing, theme, borderRadius }) => ({
  coveredLogo: {
    height: 120,
    width: 200,
  },
  cardContainer: {
    width: "100%",
    marginBottom: spacing[5],
  },
  button: {
    marginTop: spacing[3],
    width: ["100%", "auto"],
  },
  content: {
    display: "flex",
    padding: [spacing[4], spacing[6], spacing[8]],
  },
  illustration: {
    marginLeft: 128,
    marginRight: 64,
    display: "flex",
    alignItems: "center",
  },
  heading: {
    marginTop: spacing[3],
    marginBottom: spacing[2],
  },
  card: {
    borderRadius: borderRadius[1],
    border: "solid 1px",
    borderColor: theme.border.default.base,
  },
}));

const Card = ({ children, style }: { children: ReactNode; style: Style }) => (
  <div css={[styles.card, style]}>{children}</div>
);

export interface PartnershipCardProps {
  header: string;
  description: string;
  illustration: React.ReactNode;
  style?: SerializedStyles;
  buttonText: string;
  link: string;
  type: "Insurance";
}

const PartnershipCard = ({
  header,
  type,
  description,
  illustration,
  style,
  buttonText,
  link,
}: PartnershipCardProps) => {
  const { width: windowWidth } = useWindowSize();
  return (
    <Card style={[styles.cardContainer, style]}>
      <div css={styles.content}>
        <div>
          <Badge variant="base" icon={<Tag height="17px" width="17px" />}>
            {type}
          </Badge>
          <Heading size="4xl" style={styles.heading}>
            {header}
          </Heading>
          <Paragraph>{description}</Paragraph>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <PrimaryButton style={styles.button}>{buttonText}</PrimaryButton>
          </a>
        </div>
        {windowWidth > 1200 && <div css={styles.illustration}>{illustration}</div>}
      </div>
    </Card>
  );
};

const Marketplace = () => {
  useDocumentTitle("Marketplace | Fraction");

  return (
    <DashboardPage
      heading="Marketplace"
      subHeading="These are companies that Fraction likes and has partnered with to be able to offer you deals on their services."
    >
      <PartnershipCard
        header="Covered"
        type="Insurance"
        buttonText="Get your quote"
        link="https://getquote.itscovered.com/fraction"
        description="Covered is the leading insurance marketplace for savvy buyers. Shop and compare insurance products from over 30 nationwide carriers in minutes with competitive coverage and rates. Get your personalized quote today!"
        illustration={<CoveredLogo css={styles.coveredLogo} />}
      />
    </DashboardPage>
  );
};

export default memo(Marketplace);
