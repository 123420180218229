import { ResponsiveBar } from "@nivo/bar";
import { usePortfolioSize } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { Skeleton } from "src/components";
import { useToggler } from "src/hooks";

import { formatters } from "@fraction/shared";

import { ChartCard } from "../ChartCard";

const MARGIN = { top: 20, right: 0, bottom: 40, left: 80 };
const AXIS_BOTTOM = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: "Quarter",
  legendPosition: "middle",
  legendOffset: 32,
} as const;

const Tooltip = (props: any) => {
  return (
    <div className="shadow p-2 bg-white rounded">
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Portfolio: </b>
        {formatters.number.getCurrencyFromNumber(props.data?.totalSum)} ({props.data?.totalCount || 0})
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Fundings: </b>
        {formatters.number.getCurrencyFromNumber(props.data?.fundingsSum)} ({props.data?.fundingsCount || 0})
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Repayments: </b>
        {formatters.number.getCurrencyFromNumber(props.data?.payoffsSum)} ({props.data?.payoffsCount || 0})
      </p>
    </div>
  );
};

export function PortfolioSizeChart() {
  const { data, isLoading } = usePortfolioSize();
  const { Toggle, on: showCount } = useToggler({
    id: "portfolioSizeToggle",
    label: "Show count",
    defaultValue: false,
  });

  if (isLoading) {
    return (
      <div className="col-span-3">
        <Skeleton width="100%" height={200} />
      </div>
    );
  }

  return (
    <ChartCard
      isEmpty={data.length === 0}
      cardClassName="col-span-3"
      className="h-[200px]"
      header="Portfolio size"
      headerComponents={
        <div className="flex row gap-3">
          <Toggle />
        </div>
      }
    >
      <ResponsiveBar
        data={data}
        keys={
          showCount
            ? ["totalCount", "fundingsCount", "payoffsCount"]
            : ["totalSum", "fundingsSum", "payoffsSum"]
        }
        indexBy="quarter"
        margin={MARGIN}
        padding={0.3}
        valueScale={{ type: "linear" }}
        tooltip={Tooltip}
        indexScale={{ type: "band", round: true }}
        groupMode="grouped"
        axisBottom={AXIS_BOTTOM}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          format: showCount ? undefined : formatters.number.getCurrencyFromNumber,
        }}
        enableLabel={showCount}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="#ffffff"
      />
    </ChartCard>
  );
}
