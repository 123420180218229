import { formatters } from "@fraction/shared";
import _ from "lodash";
import { useApplicationAuthed } from "src/hooks/useApplication";
import { cn } from "src/utilities/shadcnUtils";

export const AppTimeline = ({ applicationId, className }: { applicationId?: string; className?: string }) => {
  const { data: app } = useApplicationAuthed({
    id: applicationId,
  });

  const events = _.sortBy(
    Object.entries(app || {}).filter(([key, value]) => key?.startsWith("status_change_") && value),
    1
  ).reverse() as [string, Date][];

  if (!events.length) {
    return null;
  }

  return (
    <div className={cn("border p-4 border-gray-200 rounded")}>
      <p className="text-2xl mb-1 font-bold">Timeline</p>
      <p className="text-md mb-3">View the timeline of your application.</p>
      <div className="border-t border-gray-400 flex flex-col gap-y-4 py-4">
        {events.map(([status, time]) => (
          <div className="flex flex-row justify-between px-3">
            <p className="text-sm font-medium">{_.startCase(status).replace("Status Change ", "")}</p>
            {time ? (
              <p className="text-sm font-medium">{formatters.date.formatDate(new Date(time))}</p>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};
