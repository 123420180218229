import { SerializedStyles } from "@emotion/react";
import { MotionProps, motion } from "framer-motion";
import { InputHTMLAttributes, ReactNode, forwardRef, memo, useCallback, useState } from "react";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

import { LightText } from "../v1/Text";

// beware: children has a higher priority to label
export interface CheckboxProps
  extends Omit<
    React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & MotionProps,
    "onChange" | "style" | "onAnimationStart" | "onDragStart" | "onDragEnd"
  > {
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  style?: SerializedStyles;
  labelStyle?: SerializedStyles;
  label?: string;
  name?: string;
  htmlFor?: string;
  large?: boolean;
  children?: ReactNode;
}

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  largeContainer: {
    alignItems: "flex-start",
  },
  label: {
    maxWidth: 345,
    fontSize: 14,
    textAlign: "left",
    color: colors.BLACK_TEXT,
    letterSpacing: "0.01em",
    lineHeight: "145%",
  },
  touchable: {
    padding: 0,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  checkbox: {
    height: 24,
    width: 24,
    minWidth: 24,
    borderRadius: 4,
    "@media(max-width: 420px)": {
      height: 24,
      width: 24,
      minWidth: 24,
      borderRadius: 4,
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: colors.LINES,
    borderStyle: "solid",
    marginRight: 20,
  },
  checkboxFiller: {
    height: 16,
    width: 16,
    minWidth: 16,
    borderRadius: 2,
    "@media(max-width: 420px)": {
      height: 16,
      width: 16,
      minWidth: 16,
      borderRadius: 2,
    },
    backgroundColor: colors.palette.GREEN,
  },
  largeCheckbox: {
    height: 48,
    width: 48,
    minWidth: 48,
    borderRadius: 8,
  },
  largeCheckboxFiller: {
    height: 32,
    width: 32,
    borderRadius: 4,
  },
  hideNativeCheckbox: {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: "1px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    position: "fixed",
    width: "1px",
  },
});

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checked,
      defaultChecked,
      onChange,
      style,
      label,
      name,
      htmlFor,
      labelStyle,
      large,
      children,
    }: CheckboxProps,
    forwardedRef
  ) => {
    const [innerCheckedState, setInnerCheckedState] = useState(checked || defaultChecked);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event.target.checked);
        if (checked === undefined) {
          setInnerCheckedState(event.target.checked);
        }
      },
      [onChange, checked]
    );

    return (
      <label htmlFor={htmlFor}>
        <input
          id={htmlFor}
          name={name}
          type="checkbox"
          ref={forwardedRef}
          checked={checked !== undefined ? checked : undefined}
          defaultChecked={defaultChecked}
          onChange={handleChange}
          css={styles.hideNativeCheckbox}
        />
        <div css={style}>
          <div css={[styles.container, large && styles.largeContainer]} aria-hidden="true">
            <div css={[styles.checkbox, large && styles.largeCheckbox]}>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: checked ?? innerCheckedState ? 1 : 0 }}
                css={[styles.checkboxFiller, large && styles.largeCheckboxFiller]}
              />
            </div>
            {children || (
              <LightText paragraph style={[styles.label, labelStyle]}>
                {label}
              </LightText>
            )}
          </div>
        </div>
      </label>
    );
  }
);

export default memo(Checkbox);
