import { entities, formatters } from "@fraction/shared";
import _ from "lodash";
import { Link } from "react-router-dom";
import { TitleWithQr } from "src/apps/BrokerDashboard/components/TitleWithQr";
import { ApplicationOverview } from "src/apps/PreFundedDashboard/components/ApplicationOverview";
import { Skeleton } from "src/components";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { useApplicationsQuery } from "src/hooks/useApplication";
import { Document } from "src/images";

export const BrokerDashboardOverview = ({
  status = "active",
  accountType,
}: { status?: "active" | "all" | "closed"; accountType: "broker" | "conveyancer" }) => {
  const { data: applications, isLoading } = useApplicationsQuery({ status, accountType, refetch: true });
  useDocumentTitle(`${_.startCase(status)} deals | Fraction`);

  console.log(applications?.length);

  return (
    <div className="p-6 flex flex-col gap-2">
      <TitleWithQr
        title={`Your ${status} deals`}
        description={
          <>
            Keep track of your deals and see where they are in the process. If you have any questions, feel
            free to reach out to{" "}
            <a className="text-green" href="mailto:ops@fraction.com">
              ops@fraction.com
            </a>
          </>
        }
      />
      <div className="gap-2 flex flex-row flex-wrap w-full">
        {isLoading
          ? Array.from({ length: 2 }).map((_, i) => {
              return <Skeleton height={450} width={700} className="w-full max-w-3xl h-[286px]" key={i} />;
            })
          : null}
        {_.sortBy(
          applications?.map((application) => (
            <AppCard loading={isLoading} key={application.id} application={application} />
          )),
          "createdAt"
        ).reverse()}
        {!isLoading && !applications?.length ? (
          <div className="mt-4 max-w-2xl border rounded-lg border-green-400 p-6 py-3 bg-green-50 flex flex-row items-center">
            <Document height={160} width={160} className="mr-6" />
            <div>
              <p className="text-xl font-semibold mb-1">You do not currently have any {status} deals</p>
              <p>
                If you'd like to submit an application, you can find Fraction on Filogix Expert, Velocity,
                Finmo, and Scarlett under the "private lender" section.
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const AppCard = ({ application, loading }: { application: entities.ApplicationT; loading?: boolean }) => {
  return (
    <Link key={application.id} className="group relative w-fit" to={`/app/${application.id}`}>
      <ApplicationOverview
        header={formatters.application.applicantNames(application)}
        className="group-hover:border-green  max-h-[500px] overflow-y-scroll"
        app={application}
      />
      <div className="absolute top-0 bottom-0 right-0 left-0 max-w-3xl group-hover:bg-green/10 pointer-events-none" />
    </Link>
  );
};
