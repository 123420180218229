import { LogLevel, parseStage, utilities } from "@fraction/shared";

const STAGE: "dev" | "prod" | "local" | undefined = parseStage(
  import.meta.env.VITE_STAGE || import.meta.env.STORYBOOK_APP_STAGE
);

const IS_PROD = STAGE === "prod";
const IS_LOCAL = STAGE === "local";
const BROWSER_DOMAIN = IS_PROD ? "fraction.com" : document.location.host?.replace("https://", "");
const BROWSER_HOST = BROWSER_DOMAIN.split(":")[0];

const config = {
  isProd: IS_PROD,
  isLocal: IS_LOCAL,
  stage: STAGE, // local, dev, prod
  logLevel: utilities.number.parseInt(import.meta.env.VITE_LOG_LEVEL, LogLevel.LOG),
  baseURL: IS_LOCAL
    ? `http://${BROWSER_HOST}:9000`
    : IS_PROD
    ? "https://api.fraction.com"
    : "https://dev.fraction.com",
  segmentWriteKey: IS_PROD ? "Z68ZA33fqhf2k8XHDeUBBCFdaYuCAQ3Y" : "NPZJ98kojGCoTTcPpbJs5JUFFKJfaD49",
  datadogClientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN || "NO_TOKEN",
  launchDarklyClientID: IS_PROD ? "62058981f91f0011fdab6844" : "62058981f91f0011fdab6843",
  googlePlacesAPIKey:
    import.meta.env.VITE_GOOGLE_PLACES_API_KEY || import.meta.env.STORYBOOK_GOOGLE_PLACES_API_KEY,
  s3URL: {
    publicMedia: "https://fraction-public-media.s3.ca-central-1.amazonaws.com",
    legalDocs: "https://fraction-legal-docs.s3.us-west-1.amazonaws.com",
  },
  browserDomain: BROWSER_DOMAIN,
  // if you set ANYTHING for localhost for cookie domain, nothing will get saved.
  cookieDomain: IS_LOCAL ? undefined : BROWSER_DOMAIN,
};

export default config;
